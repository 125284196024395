import './App.css';
import Home from './components/home';
import PrivacyPolicy from './components/privacyPolicy';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import TermsOfServices from './components/termsOfServices';
import ContactUs from './components/contactUs';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/privacypolicy" element={<PrivacyPolicy/>}/>
        <Route exact path="/terms-of-services" element={<TermsOfServices/>}/>
        <Route exact path="/ContactUs" element={<ContactUs/>}/>
      </Routes>
    </Router>
  );
}

export default App;
