import React from "react";
import "./contactUs";
import "./privacyPolicy";
import "./termsOfServices";
import "../index.css";
import "../index";

class Home extends React.Component
{
    
render() {
return(
    <div>
  <header className="design">
    <div class="banner">
        <div class="container">
            <img class="header-logo" src="./images/interviewbot_icon.png"  alt=""></img>
            <h1>Interview Bot</h1>
            <h3>Automate your interview process</h3>
            <span>
                <a href="https://slack.com/oauth/v2/authorize?client_id=486491643953.2557770521936&scope=app_mentions:read,channels:history,channels:join,channels:manage,channels:read,chat:write,groups:history,groups:read,groups:write,im:history,im:read,im:write,mpim:history,mpim:read,mpim:write,team:read,users.profile:read,users:read,users:read.email,chat:write.public&user_scope=chat:write,users:read,channels:write,im:write,mpim:write,groups:write"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
            </span>
        </div>
    </div>
    </header>
    <body className="design">
    <div class="container">
      <div class="col">
        <div class="card">
          <h4>How it works</h4>
          <p class="instructions">Step 1: Select interviewers</p>
          <img src="./images/selectinterviewers.png" class="start" alt="selectInterviewers"></img>

          <p class="instructions">Step 2: Create Job Roles</p>
          <img src="./images/createJobRole.png" class="start" alt="createjobrole"></img>

          <p class="instructions">Step 3: Create Jobs </p>
          <img src="./images/createJob.png" class="start" alt="createjob"></img>

          <p class="instructions">Step 4: Invite Applicant to slack</p>
          <img src="./images/inviteCandidate.png" class="start" alt="invitecandidate"></img>

          <p class="instructions">Step 5: Bot Starts the Interview Process</p>
          <img src="./images/interviewProcess.png" class="start" alt="interviewprocess"></img>

          <p class="instructions">Step 6: Candidate Private Channel</p>
          <img src="./images/candidatechannel.png" class="start" alt="candidatechannel"></img>


          <h4 class="footer-msg">Interview Bot - Automate your interview process!</h4><br></br>
          <span>
          <a href="https://slack.com/oauth/v2/authorize?client_id=486491643953.2557770521936&scope=channels:read,chat:write,groups:read,groups:write,im:history,im:write,team:read,users:read,users:read.email&user_scope=users:read"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
          </span>
				<div class="feedback">
					<div class="line"><div class="feedback-text">Have feedback or questions? <a href="/ContactUs">Contact us</a></div></div>
				</div>
				<div class="feedback">
					<div class="line"><div class="feedback-text">
                    <a href="/privacypolicy">Privacy Policy</a></div></div>
                <div>
                    <a href="/terms-of-services" target="_blank">Terms of Service</a>
                </div>
            
            </div>
        </div>
    </div>
    </div>
    </body>
    </div>
        );
    }
}

export default Home;


