import React from "react";
import axios from 'axios';
import "../index.css";

class ContactUs extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
          name: '',
          phone: '',
          email: '',
          message: ''
        }
    }
    handleSubmit(e){
      e.preventDefault();
      axios({
        method: "POST",
        url:"http://localhost:3002/send",
        data:  this.state
      }).then((response)=>{
        if (response.data.status === 'success') {
          alert("Message Sent.");
          this.resetForm()
        } else if (response.data.status === 'fail') {
          alert("Message failed to send.")
        }
      })
    }
  
    resetForm(){
      this.setState({name: '',phone: '', email: '', message: ''})
    }
    render() {
      return(
        <div className="design">
          <div className="container">
          <h1 className="contacth1">Contact Form</h1>
            <div className="card">
          <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)} />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input type="text" className="form-control" value={this.state.phone} onChange={this.onPhoneChange.bind(this)} />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email address</label>
              <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>
        </div>
        </div>
      );
    }
  
  onNameChange(event) {
      this.setState({name: event.target.value})
    }
  
    onPhoneChange(event) {
      this.setState({phone: event.target.value})
    }

  onEmailChange(event) {
      this.setState({email: event.target.value})
    }
  
  onMessageChange(event) {
      this.setState({message: event.target.value})
    }
  
  handleSubmit(event) {
    }
  }


  export default ContactUs;